$cubeSpacing: 100px;

.wrap-top-nav {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 111;
  background-color: #01344B;
  pointer-events: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  * {
    font-size: 16px;
    letter-spacing: 3px;
  }

  span {
    &.white {
      color: #FFFFFF;
    }
    &.black {
      color: black;
    }
    text-decoration: none;
    display: block;
    padding: 6px 12px;
    cursor: pointer;
  }

  .menu-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .nav-menu {
    width: 100%;
    position: relative;
    pointer-events: auto;
    text-align: center;
    // max-width: 1280px;
    display: inline-block;
    vertical-align: top;
    margin-top: 29px;

    &.disabled .left li,
    &.disabled .right li {
      pointer-events: none;
    }

    .left {
      width: calc(45% - #{$cubeSpacing / 2});
      transform: translateX(75%);
      z-index: 1;
      bottom: 18px;

      li:first-of-type {
        transform: translateX(50%);
      }

      li:last-of-type {
        transform: translateX(-50%);
      }
    }

    .nav-cube {
      text-align: center;
      pointer-events: auto;
      z-index: 2;
      margin: 0 20px;

      & > div {
        display: inline-block;
      }

      img{
        cursor: pointer;
      }
    }

    .right {
      transform: translateX(-75%);
      width: calc(45% - #{$cubeSpacing / 2});
      z-index: 1;
      bottom: 18px;

      li:first-of-type {
        transform: translateX(50%);
      }
      li:last-of-type {
        transform: translateX(-50%);
      }
    }

    ul {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
      vertical-align: middle;

      /* 4 items */
      li:first-child:nth-last-child(4),
      li:first-child:nth-last-child(4) ~ li {
        width: 25%;
      }

      /* 3 items */
      li:first-child:nth-last-child(3),
      li:first-child:nth-last-child(3) ~ li {
        width: 33%;
      }

      /* 2 items */
      li:first-child:nth-last-child(2),
      li:first-child:nth-last-child(2) ~ li {
        width: 50%;
      }

      li {
        color: #fff;
        display: inline-block;
        list-style-type: none;
        opacity: 0;
        position: relative;
        text-align: center;
        transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
        user-select: none;
        vertical-align: middle;

        &:not(.placeholder) span {
          font-weight: 400;
          position: relative;

          // &::before {
          //   content: '';
          //   height: 1px;
          //   width: 0;
          //   background: #FFDB00;
          //   position: absolute;
          //   bottom: 0;
          //   opacity: 0;
          //   transition: all 0.2s;
          // }
        }
      }

      // li.active {
      //   /*
      //   span {
      //     &::before {
      //       opacity: 1;
      //       width: 97px;
      //     }
      //   }
      //   */
      // }
    }
  }

  .btn-search{
    position: absolute;
    bottom: 23px;
    right: 50px;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    img{
      width: 28px;
    }
  }

  &.body-container {
    padding: 0px 32px;
  }

  .icon-cube-small {
    cursor: pointer;
    width: 264px;
    object-fit: contain;
  }

  .nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0;
    margin: 0;
    width: fit-content;

    @media only screen and (max-width: 1365px) {
      gap: 24px;
    }  

    li {
      list-style-type: none;
      color: #FFF;
      font-family: "Adobe Caslon Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      display: flex;
      height: 19px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1365px) {
        height: 15px;
      }  
  

      span {
        padding: 0;
        height: 100%;

        @media only screen and (max-width: 1365px) {
          font-size: 12px;
        }  
      }
    }
  }
}

.wrap-top-nav.main-canvas {
  background-color: transparent;
}

.wrap-top-nav.gallery-main-canvas {
  background-color: #F9F8F9;
}
.wrap-top-nav.show {
  .nav-menu {
    ul {
      transform: translateX(0) !important;

      li {
        opacity: 1;
        transform: translateX(0) !important;
        transition: opacity 0.5s ease-in, transform 0.5s ease-in-out;

        &:hover {
          span {
            &::before {
              width: 97px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.nav-cube-onboard {
  display: flex;
  width: 100%;
  height: 200px;
  padding: 50px 0px 13.724px 0px;
  justify-content: center;
  align-items: center;
  z-index: 111;
  position: absolute;
  top: 0;

  img {
    width: 230px;
    cursor: pointer;
  }
}