.views-wrap-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100dvh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: relative;
      background-color: black;

      &::after {
        content: "";
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.35) 100%
        );
        display: block;
        height: 200px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
    }
  }
  .slick-dots {
    bottom: 100px;

    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }
}

.btn-gallery {
  display: block;
  position: absolute;
  top: calc(50% - 26px);
  cursor: pointer;
}

.btn-gallery.prev-slide {
  left: 69px;
  color: rgba(255, 255, 255, 0.6);
}

.btn-gallery.next-slide {
  right: 69px;
  color: rgba(255, 255, 255, 0.6);
}

.wrap-create-gallery-modal {
  .modal-content {
    border-radius: 0px;
  }

  .content {
    margin-bottom: 32px;

    .form-control {
      border-radius: 0px;
    }
  }

  .modal-form__title {
    font-size: 20px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 14px;
  }

  button {
    border: 0px;
    padding: 0px;
    text-decoration: underline;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
  .wrap-select-gallery-type {
    .css-1s2u09g-control {
      border-radius: 0;
    }
  }
}
.wrap-media-picker {
  height: 400px;
  overflow-y: scroll;
  .list-group {
    border-radius: 0;
    .list-group-item {
      border: 1px solid #c1c1c1;
    }
    .row {
      align-items: center;
    }
  }
}
.close-btn {
  width: 100%;
  position: absolute;
  top: 100px;
  right: 70px;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  img {
    position: absolute;
    right: 0;
  }
}

.wrap-close-btn {
  position: absolute;
  bottom: 80px;
  z-index: 1000;
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: center;
  div {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    span {
      color: #fff;
      font-family: "Adobe Caslon Pro";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      text-transform: uppercase;
    }
    img {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .rotate-img {
      transform: rotate(180deg);
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .reopen {
    margin-left: 12.5px;
  }
  .close {
    margin-right: 12.5px;
  }
}
