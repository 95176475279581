.panorama-side-bar {
  position: absolute;
  top: 94px;
  z-index: 1;
  height: calc(100svh - 191px);
  padding: 18px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .list-panorama {
    background: rgba(0, 0, 0, 0.35);
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .list-panorama-item {
      padding: 10px 0;
      cursor: pointer;
    }
    .item-name {
      color: #efecec;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
    .active{
      color: #FFFFFF;
    }
  }
}
::-webkit-scrollbar-thumb {
  display: none;
}
#myProgress {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 100;
  height: 10px;
  background-color: black;
  display: none;
}
#myBar {
  position: absolute;
  width: 1%;
  height: 10px;
  background-color: grey;
  display: none;
}
#wrap-panorama-view{
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.loading-virtual{
  width: 620px;
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  span{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'cpsp' on;
    color: #FFFFFF;
  }
}

.day-night{
  position: absolute;
  top: 150px;
  z-index: 1;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  flex-direction: row;
  button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: #FFFFFF;
    border-radius: 50px;
    color: #000000;
    margin: 0 5px;
    border: none;
    padding: 5px 20px;
  }
  .isActive{
    background: #000000;
    color: #FFFFFF;
  }
}
