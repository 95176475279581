$font-family-sans-serif: 'graphie',
sans-serif; // Override mdb.css

// $font-main: 'Centra No1 TRIAL',
// sans-serif;
// $font-title: 'PP Hatton',
// sans-serif;

$list-group-bg: transparent;
$list-group-border-color: transparent;
$list-group-item-color-scale: 0.75;
$list-group-hover-bg: transparent;
$list-group-active-bg: transparent;
$list-group-active-border-color: transparent;

$card-border-radius: 0;
$enable-negative-margins: true;

$colors : (
  "default": #FFDB00,
  "walking": #FEAD77,
  "bike": #84CFEC,
  "car": #EE7C96,
  "tram": #A48DD2,
  "bus": #8CE2D0,
  "train": #FFADDE,
);

$form-range-track-height:         15px;
$form-range-track-bg:             #000;
$form-range-track-border-radius:  0;
$form-range-track-box-shadow:     inset 2px 2px 4px 2px rgba(#000, 0.1);

$form-range-thumb-width:                   45px;
$form-range-thumb-height:                  $form-range-track-height;
$form-range-thumb-bg:                      #6DCC9D;
$form-range-thumb-border-radius:           0;
$form-range-thumb-box-shadow:              $form-range-track-box-shadow;
$form-range-thumb-focus-box-shadow:        $form-range-track-box-shadow;
$form-range-thumb-active-bg:               tint-color($form-range-thumb-bg, 70%);
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$spacer: 1rem;
$white: #fff;
