@import '../../common.scss';

.sidenav {
  padding: $spacer;

  &__heading {
    padding: $spacer/2 $spacer $spacer * 0.75;
    flex-shrink: 0;

    &__icon {
      font-family: Adobe Caslon Pro;
      font-size: 27px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__wrapper{
    padding: 0 30px;
  }

  &__body {
    flex: 1;
    overflow-y: scroll;
    justify-content: space-between;
  }

  &__footer {
    padding: 0 30px;
    .list-group {
      &-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  &--dark {
    background-color: transparentize(#1e1e1e, 0.9);

    .list-group {
      margin-bottom: 10px;

      &-item {
        color: $white;
      }
    }
  }
}
