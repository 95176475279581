.renders-content {
  .card-renders {
    background-color: #ffffff;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    height: 130px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .title {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #fff;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infinity {
    background-color: #e3e3e3;
  }
}

.static-page-wf.gallery-landing {
  //overflow-y: hidden;
}

.gallery-content {
  height: 115%;
  overflow: overlay;

  @media screen and (max-width: 1366px) {
    height: 79.5%;
  }
}

.films-content {
  padding: 60px 108px 130px 108px;
  background-color: #fcfcfc;
  height: 100%;

  .card-films {
    width: 100%;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    height: 458px;
    background-color: #ffffff;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    .sub {
      size: 22px;
      font-weight: 600;
      color: #fff;
    }

    .title {
      size: 31px;
      font-weight: 700;
      color: #fff;
    }

    .divider {
      height: 1.5px;
      width: 60px;
      background: #fff;
      margin-top: 13px;
      margin-bottom: 39px;
    }

    img {
      width: 75px;
      cursor: pointer;
    }
  }

  .infinity {
    background-color: #e3e3e3;
  }

  .wrap-video-film {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    video {
      width: 100vw;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.floor-plans-content {
  padding-bottom: 66px;
  background: #fcfcfc;
  width: 100%;
  height: 100%;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  //position: relative;

  .btn-floor {
    position: absolute;
    bottom: 35.87px;
    right: 40px;
    cursor: pointer;
    z-index: 99;

    display: inline-flex;
    align-items: flex-end;
    gap: 10px;

    .btn-floor-text {
      color: #01344b;
      font-size: 13px;
      font-family: Adobe Caslon Pro;
      letter-spacing: 1.56px;
      text-transform: uppercase;
    }
  }

  .content-plans {
    .plans-body {
      margin: 0 221px 0 248px;
      position: relative;

      .plans-marker {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.header-content {
  margin-top: 66px;
  width: 100%;
  display: flex;
  height: fit-content;
  padding: 0 273px;
  margin-bottom: 30px;

  .aw-title {
    color: #01344b;
    font-size: 30px;
    font-family: Adobe Caslon Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }
}

.aw-body-content {
  width: 100%;
  padding: 70px 200px 0 273px;

  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2d292780;
  }

  .aw-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 600px;

    .car-floor {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid #6b8fa3;
      cursor: pointer;
      width: 110px;
      height: 110px;

      &:hover {
        background: #6b8fa3;
        span {
          color: #fff;
        }
      }

      span {
        color: #6b8fa3;
        font-size: 30px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 2.1px;
        text-transform: uppercase;
      }
    }
  }
}

.infinity {
  background-color: #e3e3e3;
}

.modal-floor {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  justify-content: center;
  align-items: center;
  display: flex;

  .modal-body {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .modal-close {
      padding: 50px 50px 0 0;
      text-align: right;

      .modal-close-icon {
        cursor: pointer;
      }
    }

    .modal-floor-container {
      margin-top: 166px;
    }
  }

  .header-content {
    margin-top: 0;
  }

  .aw-body-content {
    .car-floor.isSelected {
      border: 1px solid #6b8fa3;
      background: #6b8fa3;

      span {
        color: #ffffff;
      }
    }
  }
}

.modal-view-plans {
  .btn-close-modal {
    top: 40px;
  }

  .modal-base-content {
    height: 100svh !important;
    min-width: 100vw !important;

    .modal-base-body {
      height: 100% !important;
      //background-color: #4e4d4b;
      overflow-x: hidden;
      padding-left: 40px !important;
      padding-right: 40px !important;

      .view-plans {
        height: 100%;

        .slider-floor-plan {
          .img-plans-content {
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .slick-slider {
          .slick-arrow {
            width: 25px;
            // height: 50px;
          }

          .slick-next {
            transform: rotate(180deg);
            top: 45%;
          }
        }
      }
    }
  }
}

.wrapper-media-image {
  padding-top: 60px;
  padding-bottom: 140px;

  img {
    object-fit: cover;
    cursor: pointer;
  }

  .top-0-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .top-0-left {
      display: flex;
      flex-direction: column;

      span {
        color: #01344b;
        font-size: 48px;
        font-family: "Adobe Caslon Pro";
        letter-spacing: 2.64px;
        text-transform: uppercase;
        margin-left: 200px;
        margin-top: 73px;
        margin-bottom: 56px;
        max-width: 550px;
      }

      img:last-child {
        margin-left: 137px;
      }
    }

    .top-0-right {
      padding-top: 160px;
    }
  }

  .top-1-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 115px;

    .top-1-left {
      padding-top: 100px;
    }

    .top-1-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      img:last-child {
        margin-right: 290px;
        margin-top: 74px;
      }
    }
  }

  .body-img {
    margin-top: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-img {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 90px;

    .bottom-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 60px;

      .bottom-1-right {
        display: flex;
        flex-direction: column;
        margin-right: 100px;
        justify-content: flex-end;
        align-items: flex-end;

        span {
          color: #01344b;
          font-size: 48px;
          font-family: "Adobe Caslon Pro";
          letter-spacing: 2.64px;
          text-transform: uppercase;
          max-width: 500px;
          margin-bottom: 100px;
        }
      }
    }
  }
}

.wrap-fancy-close {
  position: fixed;
  z-index: 1055;
  top: 50px;
  right: 50px;
  cursor: pointer;
}

.fancy-btn-prev {
  position: fixed;
  z-index: 1055;
  top: calc(50% - 17px);
  left: 81px;
  cursor: pointer;
}
.fancy-btn-next {
  position: fixed;
  z-index: 1055;
  top: calc(50% - 17px);
  right: 81px;
  cursor: pointer;
}

.pure-fancy-box > .fancybox__toolbar {
  display: none !important;
}
.pure-fancy-box .fancybox__slide {
  padding: 40px 8px 40px 8px !important;
  background-color: #ffffff !important;
}

.image-static-fancy-box > .fancybox__toolbar {
  display: none !important;
}
.image-static-fancy-box {
  .fancybox__slide {
    padding: 0 !important;
  }
  .fancybox__content {
    width: 100% !important;
    height: 100% !important;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.media-images-content {
  margin: 60px 108px;
  background-color: #fcfcfc;

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;

    &-col {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .media-images-content-wrapper {
    padding-bottom: 100px;
  }

  .media-item {
    position: relative;
    cursor: pointer;
  }

  .media-item-text {
    position: absolute;
    left: 10px;
    bottom: 10px;

    color: #fff;
    font-family: Roos St Regis Text;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06px;
  }

  .ct-image-1 {
    width: 575px;
    height: 350px;
  }

  .ct-image-2 {
    width: 531px;
    height: 350px;
  }

  .ct-image-3 {
    width: 1120px;
    height: 630px;
  }
}

.media-images-gallery {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 1366px) {
  .media-images-content {
    margin: 60px auto;
    max-width: 1150px;
  }
}
