.searchPage{
  .title-search{
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #01344B;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-transform: uppercase;
    font-family: 'Adobe Caslon Pro';
  }
  .search-input{
    height: 50px;
    border: none;
    width: 100%;
    flex-direction: row;
    display: flex;
    margin: 40px 0;
    input{
      height: 100%;
      width: 100%;
      border: none;
      padding: 16px 0 16px 23px;
      background-color: #F5F7F7;
    }
    button{
      border: 1px solid #01344B;
      text-transform: uppercase;
      background: #ffffff;
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #01344B;
      flex: none;
      font-family: 'Adobe Caslon Pro';
      &:hover{
        background: #C9C9C9;
        border: 2px solid #C9C9C9;
      }
    }
  }
  .wrapper{
    overflow-y: scroll;
    height: 450px;
    .item-search{
      cursor: pointer;
    }
    .card-search{
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      margin-bottom: 20px;
      background: #F5F7F7;
      span{
        color: #000000;
      }
      .title{
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: #104C49;
        flex: none;
        order: 0;
        flex-grow: 0;
        font-family: 'Adobe Caslon Pro';
      }
      .textSub{
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: #000000;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: 'Adobe Caslon Pro';
      }
    }
  }
}

.modal-search{
  .btn-close-modal{
    top: 45px;
  }
  .modal-base-body{
    height: 650px !important;
    width: 900px !important;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      width: 400px !important;
    }
  }
}
