.modal-services{
  .modal-base-content{
    min-width: 800px;
    .modal-base-body{
      padding: 0;
      background: transparent;
      border-radius: 0;
      height: 600px;
    }
  }
  .service-wrapper{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    .left-content{
      width: calc((800px - 20px) /2);
      margin-right: 20px;
      background: #fff;
      padding: 45px 30px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      span{
        font-weight: 400;
        font-size: 14px;
        line-height: 31px;
        color: #000000;
      }
      .title-content{
        font-weight: 600;
        font-size: 24px;
      }
      .des-content{
        margin-top: 30px;
      }
    }
    .right-content{
      margin-right: 0;
    }
  }

}
