.wrap-customer-session {
  font-family: Centra No1 TRIAL;
  background-color: #ffffff;
  .wrap-transport-option {
    background-color: #FFDB00;
  }
  .title-underline {
    width: fit-content;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
  }
  .btn-status {
    border-radius: 0px;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    border: 0px;
  }
  .btn-available {
    background-color: #6DCC9D;
  }
  .btn-under-offer {
    background-color: #FEAD76;
  }
  .btn-leased {
    background-color: #EE7C96;
  }
}
