@import "../../common.scss";

.wrap-explore-district {
  background: transparent;
  position: absolute;
  z-index: 9;
  top: 100px;
  height: calc(100svh - 200px);
  @media only screen and (max-width: 1180px) {
    height: calc(90svh - 240px);
    padding-right: 10px;
  }
  @media only screen and (max-width: 1366px) {
    height: calc(90svh - 200px);
    padding-right: 10px;
  }
  left: 30px;
  color: #fff;
  width: fit-content;
  overflow-y: auto;
  .wrap-list-transport {
    .card-title {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .card-body {
      padding: 0px;
    }
    .list-transport {
      padding: 0;
      list-style-type: none;
      li {
        margin-bottom: 20px;
        .transport-item {
          border-radius: 50%;
          width: fit-content;
          border: 2px solid #fff;
          width: 45px;
          height: 45px;
          svg {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
          &.active {
            background: #fedb00;
            border-color: #fedb00;
            path {
              fill: #000;
              transition: all 0.2s ease-in-out;
            }
          }

          @each $name, $value in $colors {
            &-#{$name} {
              &:hover,
              &:focus {
                border-color: $value;
                &:not(.active) {
                  svg path {
                    fill: $value;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-2col {
  column-count: 2;
  column-gap: 30px;
}

.list-1col {
  column-count: 1;
  column-gap: 30px;
}
