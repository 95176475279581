.wrap-amenities-bottom{
  position: absolute;
  width: 100vw;
  bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .item-amenity{
    cursor: pointer;
  }
  .txt-bottom{
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    span{
      color: #FFF;
      font-family: 'Adobe Caslon Pro';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.92px;
      text-transform: uppercase;
    }
    svg{
      margin-left: 10px;
    }
  }
}
