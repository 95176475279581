.wrap-cms-page {
  .page-body.content-page-body {
    margin-top: 0;
    padding: 0;

    .nav-tabs {
      padding-top: 20px;
      padding-bottom: 17px;
      justify-content: center;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
      border: none;
      gap: 30px;

      .nav-item {
        color: #2d2927;
        font-family: "Adobe Caslon Pro";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        border: none;
        padding: 0 0 3px 0;

        &.active {
          border-bottom: 1px solid #2d2927;
        }
      }
    }

    .tab-pane {
      padding-left: 108px;
      padding-right: 68px;
      margin-top: 65px;
    }

    .wrap-media-list {
      display: flex;
      height: 65vh;
      overflow: hidden scroll;
      gap: 18px;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-right: 40px;

      &::-webkit-scrollbar {
        width: 5px !important;
      }

      .wrap-media-item {
        width: 32%;
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        .content-image {
          height: 225px;
          object-fit: cover;
        }

        .content-film {
          height: 225px;
          width: 100%;
        }

        .content-title-row {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .content-title {
            color: #2d2927;
            text-align: center;
            font-family: "Proxima Nova";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 65%;
            text-align: left;
          }

          .content-action {
            display: flex;
            width: 96px;
            align-items: center;
            justify-content: space-between;
            .status {
              cursor: pointer;
            }
            .action-edit {
              padding-top: 4px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .form-switch {
    margin-top: -1px !important;
  }

  .form-switch .form-check-input {
    background-color: rgba(211, 208, 209, 0.35);
    width: 55px;
    height: 25px;
    border: none !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
    box-shadow: none;

    &:checked {
      background-color: #6b8fa3 !important;
    }

    &:focus {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
    }
  }
}

.hidden {
  display: none;
}

.wrap-update-content-modal,
.wrap-create-content-modal,
.wrap-delete-content-modal,
.wrap-replace-content-modal {
  input::placeholder {
    color: #d3d0d1;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 630px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 36px 40px;
      gap: 40px;

      .content-section.selected {
        .select__single-value {
          color: #2d2927;
        }
      }

      .unit-id.selected {
        .select__single-value {
          color: #2d2927;
        }
      }

      .select__menu {
        width: 200% !important;
      }

      .select__menu-portal {
        left: 40px !important;
        top: 380px !important;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__indicator {
        cursor: pointer;
      }

      .select__control {
        border: none;
        border-bottom: 1px solid #2d2927;
        border-radius: 0;
      }

      .select__single-value {
        color: #d3d0d1;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }

      .select__value-container {
        padding: 0;
      }
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;
      justify-content: space-between;

      .save {
        color: #6b8fa3;
        font-family: "Adobe Caslon Pro";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        padding: 12px 18px;
        border: 1px solid #6b8fa3;
        text-align: center;
        cursor: pointer;
      }

      .delete {
        color: rgba(45, 41, 39, 0.5);
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 225%;
        cursor: pointer;
      }

      .submit {
        color: #6b8fa3;
        font-family: "Adobe Caslon Pro";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        padding: 12px 18px;
        border: 1px solid #6b8fa3;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }

      .save,
      .submit {
        &.disabled {
          opacity: 0.6;
          cursor: none;
          cursor: not-allowed;
          pointer-events: all !important;
        }
      }
    }

    button.close {
      display: none;
    }

    .modal-title {
      height: 36px;
      color: #01344b;
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow: hidden scroll;
    color: #fff;
    position: relative;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-height: calc(100svh - 290px);

    .content {
      display: flex;
      flex-direction: column;

      .upload-input {
        margin: 25px 0 10px 0;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      img,
      video {
        height: 333px;
        object-fit: cover;
      }

      .upload-btn {
        position: absolute;
        width: 100%;
        height: 333px;
        top: 0;
        background: rgba(0, 0, 0, 0.4);

        &.thumbnail {
          &.has-file {
          }
        }

        &.video {
        }

        img {
          width: 50px;
          height: 50px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .upload-icon {
          margin: 0;
        }
      }

      .error {
        color: #ae6537;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .info {
      display: flex;
      gap: 20px;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .title {
      color: #01344b;
      font-family: "Adobe Caslon Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(45, 41, 39, 0.5);
    }

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;
      padding-top: 13px;

      .error {
        color: #ae6537;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .form-group {
        flex: 1 0 0;
      }
    }

    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      color: rgba(45, 41, 39, 0.5);
      text-align: center;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 17.5px */
      margin-bottom: 15px;
    }

    .form-control {
      color: #2d2927;
      text-align: left;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 17.5px */
      border: none;
      border-bottom: 1px solid #2d2927;
      border-radius: 0;
      box-shadow: none;
      padding: 0 0 13px 0;
      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

    .browser-file {
      color: #6b8fa3;
      font-family: "Adobe Caslon Pro";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      text-transform: uppercase;
      border: 1px solid #6b8fa3;
      padding: 12px 18px;
      text-align: center;
      cursor: pointer;
    }

    .import {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 10px;

      .import-title {
        color: rgba(45, 41, 39, 0.5);
        text-align: center;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
      }

      .import-form {
        display: flex;
        justify-content: flex-end;

        input {
          width: 100%;
          background: #f5f7f7;
          outline: none;
          border: none;
          padding-left: 5px;

          &:focus-visible {
            box-shadow: none;
          }

          &::placeholder {
            color: #d3d0d1;
            font-family: "Proxima Nova";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
          }
        }

        .import-button {
          color: #6b8fa3;
          font-family: "Adobe Caslon Pro";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.56px;
          text-transform: uppercase;
          padding: 8px 18px 4px 18px;
          border: 1px solid #6b8fa3;
          cursor: pointer;
        }
      }
    }
  }
}

.wrap-delete-content-modal {
  .modal-dialog {
    .modal-content {
      width: 500px !important;
      gap: 14px;
    }

    .wrap-modal-body {
      padding: 0 !important;

      .confirm {
        color: #2d2927;
        font-family: "Proxima Nova";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
      }
    }

    .modal-footer {
      margin-top: 26px;
      div {
        padding: 12px 18px;
        width: 100%;
        text-align: center;
        color: #ae6537;
        font-family: "Adobe Caslon Pro";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        border: 1px solid #ae6537;

        &:hover {
          color: #fff;
          background-color: #ae6537;
          cursor: pointer;
        }
      }
    }
  }
}

.wrap-replace-content-modal {
  .modal-dialog {
    .modal-content {
      width: 500px !important;
      gap: 29px;
    }

    .wrap-modal-body {
      gap: 0px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .wrap-cms-page .page-body.content-page-body .tab-pane {
    max-width: 1150px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}
