.btn-go-back {
  position: absolute;
  top: 25px;
  left: 8vw;
  z-index: 100;
  color: #ffffff;
  text-decoration: underline;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
