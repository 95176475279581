.wrap-email-login-page {
  .modal-form {
    color: #000;
    font-size: 14px;
    width: 636px;
    background: #fff;
    padding: 36px 40px;
    box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .modal-form__title {
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    &.underline-sm {
      &::before {
        width: 75px;
        background: #000;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .modal-form__body {
    padding-top: 13px;
  }
  .form-group {
    border-bottom: 1px solid #414345;
  }
  .modal-form__body .form-control {
    border-radius: 0;
    border: 0;
    height: 40px;
    padding: 0px;
    line-height: 2;
    background: #fff;
    font-size: 14px;
    color: #414345;
    &:focus {
      box-shadow: none;
    }
  }
  .button-group {
    margin-top: 35px;
    button {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      padding: 0px;
    }
  }
  .cancel-button {
    margin-right: 20px;
  }
}
