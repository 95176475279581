.wrap-request-support-modal {
  input::placeholder {
    color: #d3d0d1;
  }
  
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 630px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 36px 40px;
      gap: 42px;

      .select__input-container {
        margin: 0;
        padding: 0;
      }

      .select__control {
        outline-width: 0 !important;
        box-shadow: none;
        margin-top: -10px;
        .select__placeholder,
        .select__single-value {
          color: #D3D0D1;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
        }
        .select__single-value {
          color: #2D2927;
        }
      }
      
      .select__menu-portal {
        left: 40px !important;
        top: 200px !important;
      }
      
      .select__menu {
        border: none;
      }

      .select__option {
        background: #fff;
        color: #000;
        font-family: Proxima Nova;
        font-size: 14;
        font-weight: 400;
        border-bottom: 1px solid #D3D0D1;

        &:hover {
          background: #01344B;
          color: #fff;
        }
      }

      .select__indicator-separator {
        display: none;
      }

      .select__control {
        border: none;
        border-bottom: 1px solid #1A1A1A;
        border-radius: 0;
      }

      .select__value-container {
        padding: 0;
      }
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

			.finish {
				color: #6B8FA3;
				font-family: 'Adobe Caslon Pro';
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 1.56px;
				text-transform: uppercase;
				padding: 12px 18px;
				width: 100%;
				border: 1px solid #6B8FA3;
				text-align: center;
        cursor: pointer;
			}
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #01344B;

      /* Header - medium */
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;

      .error {
        color: #AE6537;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .form-group {
          flex: 1 0 0;
      }
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
			color: rgba(45, 41, 39, 0.50);
			text-align: center;
			font-family: 'Proxima Nova';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 125%; /* 17.5px */
			margin-bottom: 15px;
    }

    .form-control {
      color: #2D2927;
			text-align: left;
			font-family: 'Proxima Nova';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 125%; /* 17.5px */
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
      box-shadow: none;
			padding: 0 0 13px 0;
      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

  }
}
