.wrap-floor-plan-gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .react-transform-wrapper {
    height: 100% !important;
    width: 100vw !important;

    .floorplan-item {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #F9F8F9;
  
      &.img-not-found {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  
    .slick-slide {
      border: none;
    }
  }
}

.wrap-list-floor {
  .list-group {
    position: absolute;
    width: 100%;
    bottom: 0;

    .list-group-item {
      text-decoration: underline;
      font-size: 14px;
      color: #000;
      opacity: 1;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.close-btn-floor {
  position: absolute;
  z-index: 110;
  right: 70px;
  top: 130px;
  cursor: pointer;
}


.wrap-zoom-btn {
  position: absolute;
  bottom: 118px;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  z-index: 61;

  .m-none {
    margin: 0!important;
  }

  div{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 0 5px;
    span{
      color: #6B8FA3;
      font-family: 'Adobe Caslon Pro';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      text-transform: uppercase;
    }
    .rotate-img{
      transform: rotate(180deg);
      margin-right: 0;
      margin-left: 10px;
    }
  }
  img {
    cursor: pointer;
  }

  .zoom-out {
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    margin-left: 72px;
  }

  .zoom-in {
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    margin-right: 24px;
  }
}  
