.wrap-pano-viewer {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 90;
  transition: all 0.5s;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.btn-close-pano {
  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
