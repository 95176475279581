.hubspot-container {
  padding-right: 108px;
  padding-left: 76px;
  gap: 1.5rem;
  display: flex;
  margin-top: 85px;
  flex-direction: column;

  .hubspot-row-container {
    gap: 1.5rem;
    display: flex;
    align-items: center;
  }
  
    
  .hubspot-api-input {
    font-size: 14px;
    font-family: Proxima Nova;
    border-width: 2px;
    border-style: solid;
    border-color: #dee2e6;
    padding: 0;
  }
  .hubspot-button {
    font-family: Proxima Nova;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 700;
    font-weight: 400;
    padding-bottom: 3px;
    padding-top: 3px;
    padding-left: 12px;
    padding-right: 12px;
    border-width: 2px;
    cursor: pointer;
    background-color: white;
    border-width: 2px;
    border-style: solid;
    border-color: #dee2e6;
  }

}
