#loading-screen {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: flex-end;
  padding-left: 246px;
  color: #fff;
  text-align: center;
  background-color: black;
  padding-bottom: 160px;

  .disclaimer {
    text-align: center;
    position: absolute;
    bottom: 25px;
    width: calc(100% - 300px);
    left: 150px;
    z-index: 100;

    span {
      font-family: Adobe Caslon Pro;
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.015em;
      color: white;
    }
  }

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 0px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    overflow: hidden;
    z-index: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 100svh;
    }
  }

  .wrap-intro {
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    position: relative;
    z-index: 1;
  }

  .wrap-slide-loading {
    transition: all 1s;
    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

#intro-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-video-2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100vw;
  transform: translate(0, -50%);
  object-fit: cover;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.intro-content {
  .wrap-button {
    display: flex;
    .explore_button {
      color: #fff;
      font-family: "Adobe Caslon Pro";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 1.56px;
      text-transform: uppercase;
      background: transparent;
      border: 1px solid #fff;
      padding: 12px 18px;
      height: 37px;
      width: auto;
      &:hover {
        background-color: #ffffff;
        color: #2d2927;
      }
    }
  }

  h1 {
    font-size: calc(2rem + 2vw);
    font-family: "Philosopher", serif;
    margin-bottom: 1rem;
  }

  p {
    font-size: calc(1rem + 0.125vw);
    letter-spacing: 0.125rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrap-text {
  margin-bottom: 30px;
  text-align: start;
  font-size: 48px;
  letter-spacing: 0.055em;
  line-height: normal;
}

.wrap-button {
  button {
    background: #ffffff;
    color: #000000;
    padding: 16px;
    font-weight: bold;
    border: 1px solid #000;
    font-size: 14px;
    width: 170px;
    // font-family: system-ui;
  }
}

.disable-btn {
  opacity: 0;
  pointer-events: none;
}
