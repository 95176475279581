.content-team-modal{
  border: none !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  .bg-close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .project-name{
    margin-top: 32px;
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 25px;
    .title-project{
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      letter-spacing: 0.15em;
      color: #000000;
    }
    .row{
      margin-top: 32px;
    }
    .card-project{
      .image-project{
        height: 220px;
        background: #E4E4E4;
        margin-bottom: 30px;
      }
      .content-project{
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.15em;
        color: #000000;
      }
    }
  }
  .bg-bottom{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 25px;
    span{
      font-weight: 450;
      font-size: 14px;
      line-height: 100%;
      text-decoration-line: underline;
      color: #000000;
      cursor: pointer;
    }
  }
  .company{
    display: flex;
    flex-direction: row;
    padding: 20px 25px;
    .img-company{
      width: 400px;
      height: 450px;
      background: #E4E4E4;
    }
    .content-company{
      margin-left: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 400px - 44px);
      .header-company{
        display: flex;
        flex-direction: column;
        span{
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.15em;
          color: #000000;
        }
        .title{
          font-weight: 600;
          font-size: 24px;
          line-height: 31px;
          letter-spacing: 0.15em;
          color: #000000;
        }
        .detail{
          margin-top: 30px;
          margin-bottom: 40px;
        }
        .contact-company{
          font-weight: 600;
          font-size: 14px;
        }
      }
      .bottom-company{
        display: flex;
        justify-content: space-between;
        span{
          font-weight: 450;
          font-size: 14px;
          line-height: 100%;
          text-decoration-line: underline;
          color: #000000;
          cursor: pointer;
        }
      }
    }
  }
  .contact{
    padding: 0 25px;
    .title{
      font-weight: 600;
      font-size: 24px;
      line-height: 200%;
      color: #000000;
    }
    .info{
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      margin-bottom: 30px;
      .info-contact{
        border-bottom-width: 1px;
        border-bottom-color: black;
        border-bottom-style: solid;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        span{
          font-weight: 400;
          font-size: 14px;
          line-height: 200%;
          color: #000000;
        }
        span:last-child{
          margin-left: 50px;
        }
        .title-contact{
          width: 65px;
          display: flex;
        }
      }
    }
    .detail{
      max-height: 230px;
      overflow-y: scroll;
      flex-direction: column;
      display: flex;
      span{
        font-weight: 400;
        font-size: 14px;
        line-height: 200%;
        color: #000000;
      }
      span:last-child{
        margin-top: 20px;
      }
    }
    .contact-bottom{
      margin-top: 60px;
      span{
        font-weight: 450;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
        cursor: pointer;
      }
    }
  }

}
