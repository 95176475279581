.tg-container {
  .tg-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-bottom: 10px;

    span {
      color: #01344b;
      font-size: 30px;
      font-family: Adobe Caslon Pro;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      max-width: 95%;
    }
  }

  .tg-body {
    margin-top: 30px;
    width: 66%;
    p, ol {
      margin: 0;
    }
  }

  .tg-body > *,
  .tg-body ol li {
    color: #2D2927;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.9px;
    white-space: pre-line;
  }
}
