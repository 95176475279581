.amenities-page {
  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2D292780;
  }

  .main-content {
    width: 100%;
    background-color: #FFFFFF;

    .content-1 {
      display: flex;
      padding: 100px 246px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .aw-link {
        color: #6B8FA3;
        font-size: 13px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 1.56px;
        text-transform: uppercase;
        text-decoration: none;
        vertical-align: center;
        display: flex;
        align-items: center;
      }

      .header {
        color: #01344B;
        font-size: 48px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 2.64px;
        text-transform: uppercase;
      }

      .paragraph {
        color: #2D2927;
        font-size: 18px;
        font-family: Proxima Nova;
        line-height: 150%;
        letter-spacing: -0.9px;
      }
    }

    .content-2 {
      background: #AE6537;
      padding: 85px;
      gap: 85px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      &.blue-bg {
        background: #6B8FA3;
      }

      .ct-image-1 {
        width: 594px;
        height: 664px;
        background: #d9d9d9;
      }

      .residence-info {
        margin-top: 92px;
        max-width: 400px;

        .residence-header {
          color: #FFF;
          font-size: 30px;
          font-family: Adobe Caslon Pro;
          letter-spacing: 2.1px;
          text-transform: uppercase;
          margin-bottom: 30px;

          &.health-header {
            @media only screen and (width: 1366px) {
              width: 73%;
            }  
          }
        }

        .residence-para {
          color: #FFF;
          font-size: 14px;
          font-family: Proxima Nova;
          line-height: 125%;
          margin-bottom: 30px;
          display: flex;

          &::before {
            margin-right: 10px;
            content: "•";
          }
        }
      }
    }

    .content-3 {
      padding: 85px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .ct-image-3 {
        width: 594px;
        height: 664px;
        background: #d9d9d9;
      }

      .dining-destinations {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin-top: 92px;
        padding-left: 85px;

        .dining-destinations-header {
          color: #01344B;
          font-size: 30px;
          font-family: Adobe Caslon Pro;
          letter-spacing: 2.1px;
          text-transform: uppercase;
        }

        .dining-destinations-para {
          color: #2D2927;
          font-size: 14px;
          font-family: Proxima Nova;
          line-height: 125%;
          display: flex;
          width: 90%;

          @media only screen and (width: 1366px) {
            width: 75%;
          }  

          &::before {
            margin-right: 10px;
            content: "•";
          }
        }
      }
    }

    .content-5 {
      background-color: #D3D0D1;
      //height: 700px;
      width: 100%;
      aspect-ratio: 2;

      @media only screen and (width: 1366px) {
        aspect-ratio: 1.5;
      }

      background-image: url('/assets/images/image-amenities-5.jpg');
      background-size: cover;
      background-repeat: no-repeat;
    }

    .ct-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .ct-img-1 {
      width: 450px;
      height: 300px;
      object-fit: cover;
      margin-top: 10px;
    }
  }
}
