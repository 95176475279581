.wrap-left-nav {
  .card {
    border-radius: 0px;
    .list-group-item {
      padding: 0px;
      &:hover {
        background-color: #c1c1c1;
      }
      &.active {
        background-color: #c1c1c1;
      }
      a {
        display: block;
        padding: 0.5rem 1rem;
        color: #000000;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
}
