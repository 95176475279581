.modal-container {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  justify-content: center;
  align-items: center;
  display: flex;
  .modal-base-content {
    height: 100%;
    max-width: 900px;
    min-width: 500px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-base-body {
      padding: 20px;
      width: 100%;
      height: 500px;
      background: #ffffff;
      border-radius: 8px;
      overflow-y: scroll;
    }
  }
  .btn-close-modal{
    position: absolute;
    right: 35px;
    top:100px;
    cursor: pointer;
    z-index: 999;
  }
}

