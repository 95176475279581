$cms-page-bg: #fff;
$cms-page-color: #000000;
$cms-input: #ffdb00;
$cms-menu: #01344b;

.wrap-cms-page {
  min-height: 100svh;
  background: $cms-page-bg;

  .wrap-top-nav .nav-menu ul li.active div {
    &::before {
      opacity: 1 !important;
      width: 97px !important;
    }
    &.faq::before {
      width: 43px !important;
    }
  }

  .wrap-top-nav .nav-menu ul li:not(.placeholder) div::before {
    content: "";
    height: 1px;
    width: 0;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s;
  }

  .wrap-top-nav {
    padding: 0 50px;

    .nav-menu {
      @media screen and (max-width: 1366px) {
        ul li a div,
        ul li div {
          font-size: 1.175vw !important;
        }
      }

      .right {
        width: 43%;
        bottom: unset;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 70px;

        li {
          width: unset !important;
        }

        li:last-of-type {
          span {
            text-align: left;
            transform: translateX(-115px);
          }
          &:hover {
            span {
              &::before {
                width: 70px;
              }
            }
          }
        }
        li:first-of-type {
          span {
            text-align: left;
            transform: translateX(30px);
          }
          &:hover {
            span {
              &::before {
                width: 40px;
              }
            }
          }

          &.active {
            span {
              &::before {
                width: 40px;
              }
            }
          }
        }
      }
      .left {
        width: 43%;
        bottom: unset;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        gap: 70px;

        li {
          width: unset !important;
        }

        li:first-of-type {
          span {
            text-align: right;
            transform: translateX(50px);
          }
        }
        li:last-of-type {
          span {
            text-align: right;
            transform: translateX(-30px);
          }
        }
      }
    }
    .nav-menu-cms {
      margin: 0;
      display: flex;
      justify-content: space-between;
      gap: 70px;
      padding: 26px 0 26px 0;
      .cube-scene > img {
        width: 27px;
        height: 28px;
      }
    }
  }

  .wrap-top-nav {
    position: sticky;
    color: $cms-page-bg;
    background: $cms-page-color;
    height: 100px;

    .nav-menu ul li {
      opacity: 1;
    }

    .nav-menu ul li div {
      letter-spacing: 0 !important;
      color: #fff;

      /* Header - small */
      font-family: Adobe Caslon Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .cube__face--front {
    border-left: 2.16667px solid $cms-page-color;
    border-right: 3.25px solid $cms-page-color;
    border-bottom: 3.25px solid $cms-page-color;
    border-top: 1.625px solid $cms-page-color;
    transform: rotateY(0deg) translateZ(12px);
  }

  .cube__face--back {
    border-left: 1.625px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(180deg) translateZ(12px);
  }

  .cube__face--right {
    border-left: 3.25px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(90deg) translateZ(12px);
  }

  .cube__face--left {
    border-left: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-top: 1.625px solid #212529;
    transform: rotateY(-90deg) translateZ(12px);
  }

  .cube__face--top {
    border-top: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-left: 3.25px solid #212529;
    transform: rotateX(90deg) translateZ(12px);
  }

  .cube__face {
    background: white;
  }

  .page-container {
    position: relative;
    min-height: calc(100dvh - 100px);

    .scroll-view {
      gap: 75px;
      display: flex;
      flex-direction: column;
      min-height: 400px;
      height: 50svh;
      overflow: auto;
      padding-right: 40px;

      &::-webkit-scrollbar {
        width: 5px !important;
      }

      @media screen and (min-height: 1440px) {
        & {
          height: 60svh;
        }
      }
    }
  }

  .page-header {
    background: $cms-page-color;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;

    span {
      margin-top: 20px;
      font-size: 15px;
      color: #fff;
      text-transform: uppercase;
    }

    .f-title {
      font-size: 30px;
      font-family: "Adobe Caslon Pro";
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      color: $cms-page-bg;
    }

    &__input-search {
      max-width: 450px;

      .form-control {
        color: #414345;
        border-right: 0;
        font-size: 14px;
        border: 0;
        border-bottom: 2px solid $cms-page-color;
        border-radius: 0;
        padding: 0;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: $cms-page-color;
        }
      }
    }

    .btn {
      background: $cms-page-bg;
      color: $cms-page-color;

      &.sort-desc svg {
        transform: rotate(180deg);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .page-body {
    padding: 0 68px 0px 108px;
    margin-top: 85px;

    &.residence {
      padding: 0 108px 0px 88px;
    }

    .table > :not(caption) > * > * {
      border-bottom-width: 0px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: $cms-page-bg;
    }

    .table-responsive-custom {
      ::-webkit-scrollbar {
        width: 5px;
      }

      .table-custom {
        width: 100%;

        svg {
          cursor: pointer;
        }
      }

      thead {
        color: $cms-menu;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;

        //@media only screen and (max-width: 1366px) {
        //  .header-tenancy {
        //    padding-right: 30px;
        //  }
        //  .header-expiry {
        //    padding-right: 100px;
        //  }
        //  .header-level,
        //  .header-training,
        //  .header-precinct {
        //    padding-bottom: 45px;
        //  }
        //}

        th {
          font-size: 16px;
          font-family: "Adobe Caslon Pro";
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 35px;
          color: #01344b;

          .btn-edit {
            background: $cms-page-bg;
            color: $cms-page-color;
            font-size: 12px;
            font-weight: 700;
            line-height: 8px;
            position: relative;
            border: 0;
            left: 3px;
            bottom: 3px;
          }
        }
      }

      tbody {
        background: $cms-page-bg;
        padding-right: 40px;
        @media only screen and (max-width: 1366px) {
          height: calc(100svh - 500px);
        }
        tr {
          td {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #2d2927;
            line-height: 225%;
            font-family: "Proxima Nova";
            vertical-align: top;
            border-bottom: 1px solid #d3d0d1;
            padding: 10px 5px 10px 0px;
            overflow: hidden;
            word-wrap: break-word;

            .actionButton {
              border-radius: 50px;
              background: rgba(211, 208, 209, 0.35);
              width: 30px;
              height: 12px;
              cursor: pointer;
              transition: 0.2s ease-in;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #01344b;
              font-family: Centra No1 TRIAL;
              font-size: 16px;
              letter-spacing: 2px;

              &:hover {
                background: rgba(211, 208, 209, 0.95);
              }
            }

            .btn-more {
              line-height: 6px;
              padding: 0 8px 6px;
              margin-left: 30%;
              background: #f0f0ea;

              &:focus {
                box-shadow: none;
              }
            }

            .custom-input {
              position: relative;
              border: none;
              background: $cms-input;
              width: 100%;
              height: 25px;
              transform: translate(-2px, 0);
              // cursor: text;

              &:focus {
                outline: none;
              }
            }

            .form-check-input {
              height: 25px;
              border: 0;
              background-color: #e4e4da;
              width: 50px;
              background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");

              &:focus {
                box-shadow: none;
                border: 0;
              }
            }
          }

          .clickable {
            cursor: pointer;
          }

          .text-one-line {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          td:last-of-type {
            border-right: unset;
          }
        }
      }
    }

    .wrapper-list-faq {
      overflow-y: scroll;
      height: calc(100svh - 300px);
      .line-faq {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        margin-bottom: 20px;
        span {
          font-weight: 300;
          font-size: 16px;
          line-height: 140%;
          display: flex;
          align-items: flex-end;
          color: rgba(0, 0, 0, 0.45);
          flex: none;
          order: 0;
          flex-grow: 0;
        }
        span:last-child {
          margin-top: 5px;
          color: #000000;
        }
      }
    }

    .wrapper-table {
      table {
        thead {
          tr {
            th {
              color: #01344b;
              font-family: "Adobe Caslon Pro";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              text-transform: uppercase;
              min-width: 100px;
            }
          }
        }
        .spacing-header {
          height: 35px;
          width: 1px;
        }
        tbody {
          margin-top: 35px;
          tr {
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
            td {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              font-family: "Proxima Nova";
            }
          }
        }
      }
    }
  }

  .page-body.amenities-page-body {
    padding: 0 108px 0 76px;

    table {
      width: 100%;

      .space {
        width: 16px;
      }

      .td-3 {
        text-align: right;
      }

      .collapsed {
        .td-name,
        .td-description {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .td-edit {
        text-align: right;
        cursor: pointer;
      }

      thead {
        display: block;
        padding: 0 40px 0 0;
      }

      tbody {
        padding-right: 40px;
      }

      td {
        padding: 0 !important;
      }

      td:first-child {
        border: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #ffff;
    border-left: 12px solid #eeeeee;
    border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #88888850;
    display: block !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-left: 12px solid #d4d4d4;
    background-color: #fff;
  }

  .page-footer {
    margin-top: 20px;
    padding: 0 75px;
    .btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-outline {
    border: 1px solid #000000;
    border-radius: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
  }

  .modal-over-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .modal-notes {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 34px 40px;
      background-color: $cms-page-bg;
      max-width: 700px;
      width: 100%;
      margin: auto;

      .modal-header {
        padding: unset;
        border: unset;

        .f-title {
          font-size: 28px;

          &::before {
            content: "";
            position: absolute;
            width: 100px;
            top: 80px;
            border-bottom: 2px solid $cms-page-color;
          }
        }
      }

      .modal-body {
        padding: 36px 134px 78px 0;
        border: unset;

        .modal-body-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          border: unset;
          width: 100%;
          outline: unset;
        }
      }

      .modal-footer {
        border: unset;
        display: flex;
        justify-content: flex-start;
      }

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;

        border: unset;
        background-color: unset;
        color: $cms-page-color;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        padding: 16px;
        line-height: 15px;
        font-weight: 300;

        &:hover {
          background-color: $cms-page-bg;
        }
      }
    }
  }
}
.wrap-content-page {
  .f-title {
    color: #fff;
    text-align: center;

    /* Header - medium */
    font-family: Adobe Caslon Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }
  .nav-tabs {
    border: 0;
    border-bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 17px;
    gap: 30px;
    align-items: center;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);

    .nav-link {
      color: #000000;
      display: flex;
      justify-content: center;
      padding: 0px;

      color: #2d2927;

      font-family: Adobe Caslon Pro;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.56px;
      text-transform: uppercase;

      &:hover {
      }
      &.active {
        color: #000000;
        border-bottom: 1px solid #000000;
      }
    }
  }
}
.wrap-content-image {
  padding-bottom: 20px;
  .page-body {
    .wrap-media-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      overflow-y: scroll;
      max-height: calc(100svh - 400px);
      .wrap-media-item {
        height: 200px;
        .media-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .media-details {
          .media-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .media-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-media-actions {
      .btn-add-new-media {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}
.wrap-content-gallery {
  padding-bottom: 20px;
  .page-body {
    .wrap-gallery-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      overflow-y: scroll;
      max-height: calc(100svh - 400px);
      .wrap-gallery-item {
        height: 200px;
        .gallery-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .gallery-details {
          .gallery-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .gallery-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-gallery-actions {
      .btn-add-new-gallery {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}

.arrow {
  // position: absolute;
  cursor: pointer;
  margin-right: 8px;
}

.close-modal-btn {
  position: absolute;
  right: 3rem;
  top: 3rem;
  z-index: 9999;
  cursor: pointer;
}

.wrap-detail-modal {
  background: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 630px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 36px 40px;
      gap: 40px;

      .btn {
        border: #000;
        border-width: 1px;
      }
      .modal-form__title {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        .title {
          color: #002846;
          font-family: "Adobe Caslon Pro";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2.1px;
          text-transform: uppercase;
        }
      }
      .wrap-form {
        margin-bottom: 50px;
        .flex-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 32px;
        }
        .form-info {
          display: flex;
          flex-direction: column;
          width: 48%;
          button {
            background: #fff;
            color: #6b8fa3;
            font-weight: normal;
            border: none;
            font-family: Adobe Caslon Pro;
            font-size: 14px;
            font-style: normal;
            line-height: 125%;
            border: 1px solid #6b8fa3;
            padding: 12px 18px;
            transition: 0.2s ease-in;
            text-transform: uppercase;
            &:hover {
              background-color: #6b8fa3;
              color: white;
            }
          }
          label {
            color: rgba(45, 41, 39, 0.5);
            text-align: left;
            font-size: 14px;
            font-family: "Proxima Nova";
            line-height: 125%;
            font-weight: 400;
          }
          input,
          select,
          textarea {
            padding-left: 0;
            border: none;
            border-bottom: 1px solid #2d2927;
            font-family: "Proxima Nova";
            font-size: 14px;
            text-align: left;
            height: 50px;
            margin-top: 5px;
            background-color: #ffffff;
            &:focus {
              outline: none;
            }
          }
          span {
            color: #ae6537;
            font-family: Proxima Nova;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 60px */
          }
          input::placeholder {
            color: #d3d0d1;
          }
        }
      }
    }
  }
}

.wrap-update-amenity-modal {
  select {
    padding-left: 0;
    border: none;
    border-bottom: 1px solid #2d2927;
    font-family: "Proxima Nova";
    font-size: 14px;
    text-align: left;
    height: 50px;
    background-color: #ffffff;
    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: #d3d0d1;
  }

  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 630px;
      position: static;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 36px 40px;
      gap: 40px;
    }

    .modal-header {
      padding: 0;
      border: none;
      align-items: flex-end;
    }

    .modal-footer {
      padding: 0;
      border: none;

      button {
        display: flex;
        height: 37px;
        padding: 12px 18px;
        justify-content: center;
        flex: 1 0 0;
        background: white;
        border: 1px solid #6b8fa3;
        color: #6b8fa3;
        font-family: Adobe Caslon Pro;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1.56px;
        text-transform: uppercase;
      }
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #01344b;

      /* Header - medium */
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: static;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .error {
      color: #ae6537;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .wrap-form-group {
      display: flex;
      flex-direction: column;
      padding: 13px 0;
    }

    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      color: rgba(45, 41, 39, 0.5);
      text-align: center;
      font-family: Proxima Nova;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }

    .form-control {
      border: none;
      border-bottom: 1px solid #2d2927;
      border-radius: 0;
      padding: 0;
      color: #2d2927;

      font-family: Proxima Nova;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      right: 80px;
      top: 50px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }
  }
}
