.wrap-bottom-navigation {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  color: #000000;
  //background: #ffffff;

  div {
    z-index: 1;
  }
  .text-black {
    color: #000000;
  }
  &::after {
    content: '';
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) , transparent);
    height: 170px;
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .left-group-btn {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    font-family: sans-serif;

    .btn-item {
      padding: 0px 0px;
      display: block;
      cursor: pointer;
      margin-right: 20px !important;
      svg {
        width: 24px;
      }
    }

    .locale-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logout-btn {
      margin-left: 20px;
    }

    .btn-end{
      margin-left: 25px;
      font-family: 'Adobe Caslon Pro';
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
      flex: none;
      order: 2;
      flex-grow: 0;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      font-style: normal;
    }
  }

  .right-group-btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    gap: 26px;
    .btn-item {
      padding: 0;
      font-weight: bold;
      font-family: sans-serif;
      font-size: 13px;
      cursor: pointer;
      svg {
        width: 29px;
        height: 28px;
      }
    }

    color: #FFFFFF;
    .light {
      color: #01344B;
    }
  }
}

.wrap-bottom-navigation.light {
  background: #FCFCFC;
}

.wrap-bottom-navigation.is-show-floorplan {
  background: #FCFCFC;
}

.text-customer{
  color: #ffffff;
  text-align: right;
  font-family: 'Adobe Caslon Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  height: 20px;
}

.outline {
  z-index: 61;
  position: absolute;
  bottom: 80px;
  height: 1px;
  background: rgba(45, 41, 39, 0.50);
  width: calc(100% - 216px);
  left: 108px;
}