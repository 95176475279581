.wrap-right-panel {
  position: absolute;
  top: 100px;
  bottom: 100px;
  right: 0;
  height: calc(100svh * (800/1024)); /* header 100px; footer 100px */
  // font-family: 'Roboto', sans-serif;

  // @media only screen and (max-width: 1180px) {
  //   height: calc(100svh - 260px);
  // }
  // @media only screen and (max-width: 1366px) {
  //   height: calc(100svh - 260px);
  // }
  z-index: 100;
  width: 350px;

  .card {
    height: 100%;
    &-header {
      flex: 1 0 auto;
    }
    &-body {
      height: 100%;
      overflow: auto;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .card-body {
    padding: 30px;
    .description {
      color: #fff;
      margin: 0;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
  }

  .card-header,
  .card-footer {
    padding: 30px 25px;
    background: #fff;
    border: 0;
  }
  .card-title {
    color: #fff;
    font-family: "Adobe Caslon Pro";
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
    text-transform: uppercase;
    margin: 0;
  }
  .list-group {
    &-title {
      font-weight: 700;
      color: #000;
    }
    &-item {
      &:not(.list-group-title):not(.active):hover {
        background: #f2f2f2;
      }
      &.active {
        color: #fff;
        background: #000;
      }
    }
  }
  th {
    font-weight: 700;
  }
  th,
  td {
    white-space: nowrap;
  }
  tr {
    cursor: pointer;
  }
  tr.active {
    background: #000;
    color: #fff;
    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .btn {
    padding: 16px;
    border-radius: 0;
    border: 1px solid #000;
    box-shadow: none;
    // width: 170px;
    font-size: 14px;
    font-weight: bold;
    &-solid {
      background-color: #000;
      color: #fff;
      &:hover,
      &:focus {
        background-color: #333;
      }
    }
    &-outline {
      background: #fff;
      &:hover,
      &:focus {
        background: #f2f2f2;
      }
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &.above-timeline {
    height: calc(100svh - 260px);
  }

  .content-ctn {
    position: relative;
    width: 100%;
    height: 100%;
    .expand-img-btn {
      width: 35px;
      height: 35px;
      background: #01344B;
      position: absolute;
      top: 25px;
      right: 25px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg { fill: #fff; }
    }
  }
}

.amenity-img-full {
  position: absolute;
  width: 100svW;
  height: 100vh;
  background-color: #000;
  z-index: 1000;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .close-btn {
    width: 26px;
    height: 24px;
    position: absolute;
    top: 50px;
    right: 80px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg { fill: #fff; }
  }
}

.wrap-gallery-modal {
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 100px;
  right: 410px;
  z-index: 100;
  left: 20px;
  height: calc(100svh - 260px);
  .card {
    height: 100%;
    &-header {
      flex: 1 0 auto;
    }
    &-body {
      height: 100%;
      overflow: auto;
    }
  }
  .card-header,
  .card-footer,
  .card-body {
    padding: 2rem 2rem 1.5rem;
    background: #fff;
    border: 0;
  }
  .card-title {
    font-weight: 700;
    font-size: 25px;
    &.underline-sm {
      &::before {
        width: 96px;
        height: 1px;
        bottom: -13px;
      }
    }
  }
  .btn_close_gallery {
    margin-top: -30px;
    margin-right: -20px;
  }

  .btn-gallery.prev-slide {
    left: 10px;
  }
  .btn-gallery.next-slide {
    right: 10px;
  }
  .gallery-item {
    width: 100%;
    height: 57svh;
    @media only screen and (max-height: 880px) {
      height: 53svh;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    .gallery-hotspot {
      position: absolute;
      z-index: 100;
      cursor: pointer;
      width: 30px;
    }
  }
  .slick-dots {
    bottom: -30px;

    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #000;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #6dcc9d;
        }
      }
    }
  }
}

.wrap-right-panel-out {
  position: absolute;
  width: 350px;
  height: 100%;
  background-color: transparent;
  top: 0;
  right: 0;
}
