.accordion-sidebar {
  &-button{
    background-color: #01344B !important;
    color: #FFFFFF !important;
    padding: 0 0 15px 0 !important;
    box-shadow: unset !important;
    position: relative;
    border-bottom: 1px solid #D3D0D140 !important;
    line-height: 1;
    &-icon{
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;

      &-up {
        transform: rotate(180deg);
      }
    }

    &:focus-visible{
      outline: none;
    }

    &:focus{
      box-shadow: unset !important;
    }
  }
  .list-group{
    padding: 0 !important;

    &-item{
      &-title{
        font-weight: 400;
      }
      padding-bottom: 15px !important;
      border-bottom: 1px solid #D3D0D140;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0 !important;
      }
    }
  }
  .accordion-button:not(.collapsed)::after{
    display: none
  }
  .txt-style{
    line-height: 25px;
  }
}
