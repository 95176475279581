.aw-modal-container {
  position: fixed;
  background: #000000;
  z-index: 20000;
  //justify-content: center;
  //align-items: center;
  //display: flex;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .aw-modal-body {
    width: 100%;
    height: 100%;

    .aw-modal-btn-close {
      position: absolute;
      top: 50px;
      right: 80px;
      z-index: 9999;
      svg{
        cursor: pointer;
      }
    }

    .aw-modal-base-content {
      height: 100%;
      overflow-y: auto;
    }
  }
}
