::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.sessionPage {
  height: 100svh !important;

  .scroll-view {
    overflow: auto;

    .session-title-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url('../../assets/images/session-title.jpeg'), lightgray 50%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      @media screen and (max-width: 1368px) {
        background-position: center 55%;
      }
    }

    .session-title-logo-wrapper {
      width: 100%;
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }

    .session-title-container {
      width: 1366px;
      height: 700px;
      flex-shrink: 0;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .session-title-logo {
      width: 373.526px;
      height: 250px;
      flex-shrink: 0;
      position: absolute;
      top: 0;
    }

    .session-title-text {
      margin: 0 0 150px 150px;
      color: #FFF;
      width: 380px;
      height: 174px;
      left: 150px;

      /* Header - large */
      font-family: Adobe Caslon Pro;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.64px;
      text-transform: uppercase;

      @media screen and (max-width: 1025px) {
        width: 500px;
        padding: 30px;
        padding-left: 60px;
      }
    }
  }

  .info-user-outer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .info-user-inner {
      width: 1366px;
      display: flex;
      flex-direction: column;
      padding: 100px 0 100px 246px;
    }

    .title-name {
      color: #01344B;
      font-family: 'Adobe Caslon Pro';
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.64px;
      text-transform: uppercase;
    }

    .title-des {
      margin-top: 30px;
      color: #000;
      font-family: 'Proxima Nova';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 27px */
      letter-spacing: -0.9px;
      white-space: pre-line;
    }
  }

  .close-view {
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    height: 80px;
    margin-bottom: 80px;
    flex-shrink: 0;
    z-index: 1000;

    span {
      color: #01344b;
      text-align: center;
      font-family: "Adobe Caslon Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .unit-view-outer {
    width: 100%;
    display: flex;
    justify-content: center;

    &.position-0 { background: #ae6537 }
    &.position-1 { background: #fcfcfc }
    &.position-2 { background: #6b8fa3 }
    &.position-3 { background: #01344b }

    .unit-view-inner {
      width: 1366px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      padding: 100px 85px;
      gap: 85px;

      &.pos-0 { flex-direction: row; }
      &.pos-1 { flex-direction: row-reverse; }
    }

    .unit-details {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      &.index-0 { color: #fff; }
      &.index-1 { color: #01344B; }
      &.index-2 { color: #fff; }
      &.index-3 { color: #fff; }

      .unit-details-title {
        /* Header - medium */
        font-family: Adobe Caslon Pro;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        white-space: pre;
      }

      .unit-details-des {
        /* Body - small */
        font-family: Proxima Nova;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        /* 17.5px */
        z-index: 999;
        display: flex;
        
        ul {
          width: fit-content;              
          padding-left: 0;
          margin-right: 32px;

          li {
            margin-top: 10px;
            display: flex;
      
            &::before {
              content: "• ";
              padding-top: 3px;
            }
      
            .content{
              margin-left: 5px;
              word-break:break-word;
              max-width: 170px;
              
              span{
                white-space: pre-line;
              }
            }
          }
        }  
      }
    }
    .unit-floorplan-ctn {
      max-width: 832px;
      max-height: 488px;
      width: 65%;
      @media screen and (min-width: 1366px) {
        width: 832px;
        height: 487.5px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .unit-gallery-ctn-outer {
    width: 100%;
    display: flex;
    justify-content: center;

    .unit-gallery-ctn-inner {
      width: 1366px;
      height: 1900px;
      display: inline-flex;
      justify-content: center;
      position: relative;
      img {
        object-fit: cover;
        position: absolute;
        flex-shrink: 0;
      }
      &::after {
        height: 1px;
        width: 85%;
        content: '';
        background-color: #2D292780;
        position: absolute;
        bottom: 0;
      }
    }

    .unit-image-0 {
      top: 651px;
      margin-left: 40px;
      max-width: 370px;
      max-height: 265px;
      width: 27%;
      height: 20%;
    }

    .unit-image-1 {
      top: 633px;
      left: 0;
      max-width: 425px;
      max-height: 405px;
      width: 31%;
      height: 29%;
    }

    .unit-image-2 {
      top: 965px;
      margin-left: -40px;
      max-width: 370px;
      max-height: 254px;
      width: 27%;
      height: 19.2%;
    }

    .unit-image-3 {
      top: 100px;
      left: 90px;
      max-width: 720px;
      max-height: 479px;
      width: 52.7%;
      height: 36.3%;
    }

    .unit-image-4 {
      bottom: 100px;
      right: 44px;
      max-width: 720px;
      max-height: 479px;
      width: 52.7%;
      height: 36.3%;
    }

    .unit-image-5 {
      top: 159px;
      right: 0;
      max-width: 425px;
      max-height: 474px;
      width: 31%;
      height: 34%;
    }

    .unit-image-6 {
      top: 796px;
      right: 44px;
      max-width: 381px;
      max-height: 297px;
      width: 27.9%;
      height: 22.5%;
    }

    .unit-image-7 {
      bottom: 150px;
      left: 90px;
      max-width: 425px;
      max-height: 474px;
      width: 31%;
      height: 34%;
    }
  }

  .post-session-contact-wrapper {
    margin-top: 75px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .post-session-contact {
      display: flex;
      width: 1172px;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1025px) {
        max-width: 1000px;
        padding: 20px;
      }

      .post-session-contact-image {
        width: 391.921px;
        height: 75px;
        flex-shrink: 0;
      }

      .post-session-contact-info {
        display: flex;
        align-items: flex-start;
        gap: 50px;
        width: 636px;
        height: 36px;
        .post-session-contact-info-details {
          display: flex;
          align-items: flex-start;
          gap: 20px;
          color: rgba(45, 41, 39, 0.50);
          max-width: 235px;

          span {
            font-family: Proxima Nova;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 17.5px */
          }
        }
      }
    }
    .post-session-copyright-text {
      flex: 1 0 0;
      color: rgba(45, 41, 39, 0.50);
      width: 1172px;
      @media screen and (max-width: 1025px) {
        max-width: 1000px;
        padding: 20px;
      }
      span {
        font-family: Proxima Nova;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }
    }
  }
}
