.wrap-holding-page {
  background: #000000;
  .modal-form {
    color: #000;
    font-size: 14px;
    width: 666px;
    background: #fff;
    padding: 36px 40px;
    box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .modal-form__title {
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Adobe Caslon Pro', serif;
    color: #01344B;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2.1px;

    &.underline-sm {
      &::before {
        width: 75px;
        background: #000;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .modal-form__subtitle {
    font-size: 16px;
    line-height: 2.188em;
    margin-bottom: 26px;
  }
  .modal-form__body {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    .label-form{
      font-family: 'Proxima Nova';
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: rgba(45, 41, 39, 0.5);
    }
  }
  .modal-form__body .form-group {
    border-bottom: 1px solid #2D2927;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0 !important;
  }
  .modal-form__body .form-control {
    border-radius: 0;
    border: 0;
    height: 40px;
    padding: 0 !important;
    line-height: 2;
    background: #fff;
    font-size: 14px;
    color: #414345;
    font-family: "Proxima Nova";
    &:focus {
      box-shadow: none;
    }
    &::placeholder{
      color: #D3D0D1;
    }
  }
  .modal-form__body .btn-link {
    text-decoration: underline;
    font-family: 'Futura PT';
    font-style: medium;
    font-weight: 450;
    font-size: 14px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #000000;
  }

  .modal-form__body .btn-forgot {
    font-family: 'Adobe Caslon Pro', serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    align-items: flex-end;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #6B8FA3;
    background: none;
    border: none;
    align-self: flex-end;
    margin-bottom: 20px;
  }

  .modal-form__body .btn-holding-login {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    gap: 10px;
    height: 37px;
    border: 1px solid #6B8FA3;
    background: transparent;
    span{
      font-family: 'Adobe Caslon Pro', serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      align-items: flex-end;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #6B8FA3;
    }
  }

  .icon-cube {
    position: absolute;
    left: 50%;
    top: 119px;
    transform: translate(-50%, -50%);

    img {
      width: 230px;
    }
  }

  .wrap-modal-form {
    position: absolute;
    left: 50%;
    top: 502px;
    transform: translate(-50%, -50%);
  }
}
