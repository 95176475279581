.teamPage {
  min-height: calc(100svh - 160px);
  height: unset;

  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2D292780;
  }
  
  .apartment {
    padding-bottom: 25px;

    ul {
      margin-bottom: 13px;
    }
  }
  
  .accordion {
    width: 100%;
    
    .collapse .content {
      padding-bottom: 50px;
    }

    .paragraph2_title {
      color: #2D2927;
      font-family: Proxima Nova;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: -0.9px;
      padding-bottom: 35px;
    }

    .content {
      background-color: #EEE;
      margin: 0 -108px;
    }

    .collapsing {
      margin: 0 -108px;
      padding: 0px 108px;
    }

    .mt-43 {
      margin-top: 43px;
    }

    .sub-title {
      margin-bottom: 53px;
      color: #2D2927;
      font-family: "Proxima Nova";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: -0.9px;
    }

    .accordion-sidebar {
      &-button{
        display: flex;
        justify-content: space-between;
        font-family: Adobe Caslon Pro;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        background-color: #FCFCFC !important;
        color: #01344B !important;
        padding: 0 0 10px 0 !important;
        box-shadow: unset !important;
        position: relative;
        border-bottom: 1px solid rgba(45, 41, 39, 0.50) !important;
        line-height: 1;
        &-icon{
          position: absolute;
          right: 0;
          top: 0;
          width: 12px;
    
          &-up {
            transform: rotate(180deg);
          }
        }
    
        &:focus-visible{
          outline: none;
        }
    
        &:focus{
          box-shadow: unset !important;
        }
      }
      .list-group{
        padding: 0 !important;
    
        &-item{
          &-title{
            font-weight: 400;
    
          }
          padding: 15px 0 !important;
          border-bottom: 1px solid #D3D0D140;
        }
      }
      .txt-style{
        line-height: 25px;
      }
    }
  }
  .accordion-button:not(.collapsed)::after{
    display: none;
  }
    
  img {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
  }

  .main {
    width: 100%;

    .header {
      padding: 100px 246px;
      color: #01344B;
      background-color: #FCFCFC;
  
      font-size: 48px;
      font-family: Adobe Caslon Pro;
      line-height: normal;
      letter-spacing: 2.64px;
      text-transform: uppercase;
    }
  }

  .content {
    background-color: #FCFCFC;
    color: #01344B !important;
    padding: 50px 108PX 25px 108px;
  }

  .content-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    .specification {
      .content .content-message {
        .paragraph {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 84px;
          grid-row-gap: 50px;

          p {
            padding-bottom: 6px;
          }
          
          strong {
            color: #01344B;
            font-family: Adobe Caslon Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
          }

          ul {
            display: flex;
            flex-direction: column;

            &:first-of-type {
              padding-bottom: 26px;
            }

            li, li p {
              color: #000;
              font-family: Proxima Nova;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.9px;
            }
          }
        }
      }
    }
  }

  .paragraph {
    color: #2D2927;

    font-size: 18px;
    font-family: Proxima Nova;
    line-height: 150%;
    letter-spacing: -0.9px;
    p, p em {
      color: #000;

      font-family: Proxima Nova;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      max-width: 661px;
      white-space: pre-line;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 102px;

      li p {
        color: #000;
        font-family: Proxima Nova;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.9px;
        margin: 0 0 22px;
      }
    }
    strong {
      font-weight: 700;
    }
  }

  .abs-bottom {
    padding: 0 108px;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
  }

  .footer {
    position: relative;
    height: 500px;
  }
}
