.search-component, .create-component {
  border-radius: 45px 0 0 45px;
  background: #000;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  width: 81px;
  height: 41px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  bottom: 27px;
  z-index: 999;
  cursor: pointer;
}

.create-component {
  border-radius: 45px 0 0 45px;
  background: #000;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  width: 81px;
  height: 41px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  bottom: 78px;
  z-index: 999;
  cursor: pointer;
}

.search-component {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  
  &.open-search {
    width: 300px;

    input {
      color: #2D2927;
      text-align: left;
      font-family: "Proxima Nova";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      width: 90%;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
}

.close-component {
  border-radius: 45px 0 0 45px;
  background: #01344B;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  width: fit-content;
  height: 41px;
  padding: 8px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  bottom: 11rem;
  z-index: 999;
  cursor: pointer;

  span {
    color: #6B8FA3;
    font-family: Adobe Caslon Pro;
    font-size: 13px;
    text-transform: uppercase;
  }
}
