.wrap-list-session {
  .card {
    border-radius: 0px;
  }

  .btn-row {
  }

  .title {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.session-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  border: 1px solid #000;
  background-color: #eeeeee;

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      color: unset;
    }
  }

  p {
    margin-bottom: 0;
  }

  .session-title {
    margin-bottom: 5px;
    font-weight: 500;
  }

  .session-subtitle {
    color: #6e6e6e;
  }

  .btn-row {
    width: 100%;
    display: block;
    text-decoration: unset;
  }

  &.btn-new-session {
    border-style: dashed;
    background-color: #fff;
  }

  &.complete {
    background-color: #d4d4d4;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
