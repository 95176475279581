.top-nav-title {
    padding: 45px 0 41px 0;
    background: #01344B;
    color: #FFFFFF;
    text-align: center;

    span {
        color: #FFF;
        text-align: center;

        /* Header - medium */
        font-family: Adobe Caslon Pro;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.1px;
        text-transform: uppercase;
    }
}
