.wrap-cms-page {
	.page-body.customers-page-body {
    padding-left: 57px;

		.action-edit {
			text-align: right;
      border-radius: 50px;
      background: rgba(211, 208, 209, 0.35);
      width: 30px;
      height: 12px !important;
      cursor: pointer;
      transition: 0.2s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
		}

    .form-switch {
      margin-top: -1px !important;
    }

		.form-switch .form-check-input {
			background-color: rgba(211, 208, 209, 0.35);
      width: 55px;
      height: 25px;
      border: none !important;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
      box-shadow: none;
      &:disabled {
        opacity: unset;
      }

			&:checked {
				background-color: #6B8FA3 !important;
			}
		}

		.action-view {
			cursor: pointer;
      text-transform: uppercase;
		}

    .content {
      color: #1A1A1A;
      font-size: 24px;

      .content-header {
        display: grid;
        padding-right: 45px;
        grid-template-columns: 51px 12% 12% 10% 12% 10% 20% calc(10% - 22px) 9% 30px;
        padding-bottom: 35px;

        & > div {
          color: #01344B;
          font-family: 'Adobe Caslon Pro';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .content-list {
        max-height: calc(100svh - 500px);
        overflow: hidden auto;
        
        &::-webkit-scrollbar {
          width: 5px;
        }
      }

      .content-row {
        display: grid;
        padding-right: 40px;

        grid-template-columns: 51px 12% 12% 10% 12% 10% 20% calc(10% - 22px) 9% 30px;

        & > div {
          color: #2D2927;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 225%; /* 31.5px */
          border-bottom: 1px solid #D3D0D1;
          padding-right: 20px;
          word-wrap: break-word;
        }

        &.collapsed > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 32px;
        }

        .arrow {
          margin: 0 auto;
          border: none;
          padding-right: 0 !important;

          img {
            width: 11px;
          }
        }

        .url {
          .wrap-url {
            height: 28px;
            display: flex;
            margin-top: 2px;
          }

          .url-link {
            color: #2D2927;
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 17.5px */
            background: #F5F7F7;	
            white-space: nowrap !important;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 124px;
            display: inline-block;
            padding: 5px 10px 5px 5px;
          }

          .action-copy {
            display: inline-block;
            color: #6B8FA3;
            font-family: 'Adobe Caslon Pro';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.56px;
            text-transform: uppercase;
            padding: 4px 18px 4px 18px;
            border: 1px solid #6B8FA3;
            background: #FFF;
            cursor: pointer;
          }
        }
      }
    }
	}
}

.wrap-update-customer-modal,
.wrap-create-customer-modal,
.wrap-delete-customer-modal,
.wrap-customer-favorites-modal {

  input::placeholder {
    color: #d3d0d1;
  }
  
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100svh !important;
    transition: none !important;

    .modal-content {
      width: 630px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 36px 40px;
      gap: 40px;

      .select__menu {
        width: 200% !important;
      }
      
      .select__menu-portal {
        left: 40px !important;
        top: 380px !important;
      }

			.phone {
        display: flex;
        
				.select__indicator-separator {
					display: none;
				}

				.select__control {
					border: none;
					border-bottom: 1px solid #1A1A1A;
					border-radius: 0;
          width: 78px;
				}

      
        .select__single-value {
          color: #D3D0D1;
          font-family: 'Proxima Nova';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
        }

        .select__value-container {
          padding: 0;
        }

				.mobile-input {
					padding: 0 20px;
				}

				.mobile-code {
					width: 76px;
				}

        .phone-input {
          width: 46px;
          border-bottom: 1px solid #1A1A1A;

          .flag {
            bottom: 8px;
          }
        }
			}
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

			.finish {
				color: #6B8FA3;
				font-family: 'Adobe Caslon Pro';
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 1.56px;
				text-transform: uppercase;
				padding: 12px 18px;
				width: 100%;
				border: 1px solid #6B8FA3;
				text-align: center;
        cursor: pointer;
			}
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #01344B;

      /* Header - medium */
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: relative;
    padding: 13px 0 0 0 !important;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;

      .error {
        color: #AE6537;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .form-group {
          flex: 1 0 0;
      }
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
			color: rgba(45, 41, 39, 0.50);
			text-align: center;
			font-family: 'Proxima Nova';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 125%; /* 17.5px */
			margin-bottom: 15px;
    }

    .form-control {
      color: #2D2927;
			text-align: left;
			font-family: 'Proxima Nova';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 125%; /* 17.5px */
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
      box-shadow: none;
			padding: 0 0 13px 0;
      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

  }
}

.wrap-delete-customer-modal {
  .modal-dialog {
    .modal-content {
      width: 450px !important;
			gap: 14px;
    }

		.wrap-modal-body {
			padding: 0 !important;
			
			.confirm {
				color: #2D2927;
				font-family: 'Proxima Nova';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 125%; /* 17.5px */
			}
		}

		.modal-footer {
			margin-top: 26px;
			div {
				padding: 12px 18px;
				width: 100%;
				text-align: center;
				color: #AE6537;
				font-family: 'Adobe Caslon Pro';
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 1.56px;
				text-transform: uppercase;
				border: 1px solid #AE6537;

				&:hover {
					color: #FFF;
					background-color: #AE6537;
					cursor: pointer;
				}
			}
		}
  }
}

.wrap-update-customer-modal {
  .modal-dialog {
    .modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0 !important;
    }

    .modal-content {
      .select__menu-portal {
        left: 40px !important;
        top: 470px !important;
      }
    }

    .delete {
      color: rgba(45, 41, 39, 0.50);
			font-family: 'Proxima Nova';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 225%; /* 31.5px */
			padding: 0;
			border: none;
			background: none;
			margin: 0;
			cursor: pointer;
    }

		.save {
			color: #6B8FA3;
			font-family: 'Adobe Caslon Pro';
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 1.56px;
			text-transform: uppercase;
			border: 1px solid #6B8FA3;
			padding: 12px 18px;
			margin: 0;
			cursor: pointer;
		}
  }
}

.wrap-customer-favorites-modal {
  .wrap-loading-icon {
    bottom: unset;
    left: 48%;
    top: -40%;
    animation-duration: unset;

    .ldio-h0h1lsshjn {
      div {
        background: #000;
      }
    }
  }

  .modal-dialog {
    color: black !important;

    .modal-title {
      width: 100%;
      border-bottom: 1px solid rgba(45, 41, 39, 0.50);
    }

    .modal-content {
      width: 1160px !important;
      min-height: 224px !important;
      padding: 36px 40px 36px 0 !important;

      .modal-header {
        padding-left: 50px !important;
      }

      .content {
        color: #1A1A1A;
        font-size: 24px;
        overflow-x: hidden;

        .content-header {
          display: grid;
          grid-template-columns: 20px 114px 1fr 130px 82px 88px 1fr 1fr 2fr 2fr 1fr;
          column-gap: 7px;
          padding-left: 20px;
          padding-bottom: 35px;

          & > div {
            color: #01344B;
            font-family: 'Adobe Caslon Pro';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        .content-list {
          max-height: 221px;
          overflow: hidden scroll;
        }

        .content-row {
          display: grid;
          grid-template-columns: 20px 114px 1fr 130px 82px 88px 1fr 1fr 2fr 2fr 117px;
          column-gap: 7px;
          padding-left: 20px;

          .availability::first-letter {
            text-transform: capitalize;
          }

          & > div {
            color: #2D2927;
            font-family: 'Proxima Nova';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 225%; /* 31.5px */
          }

          &.collapsed > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .gallery-title {
          color: #01344B;
          font-family: 'Adobe Caslon Pro';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          padding: 40px 0px 10px 50px;
        }

        .gallery {
          display: flex;
          flex-direction: row;
          gap: 18px;
          padding-top: 20px;
          border-top: 1px solid rgba(45, 41, 39, 0.5);;
          width: calc(100% - 50px);
          float: right;

          .gallery-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            img {
              width: 358px;
              height: 225px;
              object-fit: cover;
            }

            span {
              color: #2D2927;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%; /* 15.4px */
            }
          }
        }
      }
    }
  }
}
