.wrap-video-intro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: black;
  iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
    video::-webkit-media-controls {
      display: none;
    }
  }
  .disable-action {
    pointer-events: none;
  }
}
